import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';
import GBLVAR from '../GlobalVariables/globalvaribles';
import dataservice from '../Services/DataService';
import { get } from 'lodash';

var tutelar; 
let tutelarEnable = false;
try{
	tutelar = new window.Tutelar({"public_key": "tutpk_live_fySLEqoxgW7F"});
	tutelarEnable = true;
} catch(err) {
	tutelarEnable = false;
}

const CryptoJS = window.CryptoJS;
var CryptoJSAesJson = {
	'stringify': function (cipherParams) {
		var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
		if (cipherParams.iv) j.iv = cipherParams.iv.toString()
		if (cipherParams.salt) j.s = cipherParams.salt.toString()
		return JSON.stringify(j).replace(/\s/g, '')
	},
	'parse': function (jsonStr) {
		var j = JSON.parse(jsonStr)
		var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
		if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
		if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
		return cipherParams
	}
}
class SamsungPay extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        cancelMessage:"",
        paydetails:{},
        ref_id:"",
        App_Details:{},
        orderData:{},
        paymentToken:{},
        App_Details_enable:false
      }
      this.completePayment = this.completePayment.bind(this);
      this.goBack = this.goBack.bind(this);
      this.encryptMethod = this.encryptMethod.bind(this);
    }
    goBack(){
        this.props.history.push(GBLVAR.PAGE_NAME.Home+'?refid='+this.props.App_Details.transaction_no);
    }
    encryptMethod(text) {
		let encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), process.env.REACT_APP_KEY, { format: CryptoJSAesJson }).toString();
		return encrypted;
	}
    completePayment(){
        var _this = this;
        console.log(_this.state.orderData)
        var getUrlParams = new URLSearchParams(window.location.search);
        var payload_option = getUrlParams.get('payload')
        console.log(payload_option);
        var data = {
            "order": _this.encryptMethod(_this.state.orderData),
        }
        var headers = {
            "secret_key" : _this.state.secret_key
        }
        data.samsung_pay_token = _this.state.samsung_pay_token;
        // data.payload = _this.state.payload;
        if(payload_option=="seon") {
			data.payload = this.state.payload;
		}
		else{
			try{
				data.tutelar_payload = `${tutelar?.getClientDetails()}`;
				data.payload = this.state.payload;	
			} catch(err){
				data.payload = this.state.payload;
			}
        }
			
		data.fraud_check = "enabled";
        dataservice.postHValues(GBLVAR.CHARGE_REQUEST_URL, headers, data)
			.then(function (response) {
				if (response !== 422 && response.data === undefined) {
					_this.setState({ loaderRun: false, showCard: "samsung_pay_screen", Error_Message: "Please check your Samsung Pay is active or not" }, () => {
						
					});
				} else if (response === 422) {
					_this.props.history.push(GBLVAR.PAGE_NAME.CardCancel);
				} else {
					if (response.data.success == 1) {
						_this.setState({ loaderRun: false }, () => {
                            _this.setState({ payment_response: response.data }, () => {
                                if (_this.state.payment_response.threed_secure == 0 && _this.state.payment_response.success == 1) {
                                    _this.props.history.push(GBLVAR.PAGE_NAME.Directsuccess + '?refid=' + _this.state.transaction_no + '&transaction_no=' + _this.state.payment_response.transaction_no + '&amount=' + _this.state.payment_response.amount + '&currency=' + _this.state.payment_response.currency + '&site_return_url=' + _this.state.payment_response.site_return_url + '&optional1=' + _this.state.payment_response.optional1 + '&optional2=' + _this.state.payment_response.optional2 + '&optional3=' + _this.state.payment_response.optional3);
                                }
                                else {
                                    _this.props.history.push(GBLVAR.PAGE_NAME.Directcancel + '?refid=' + _this.state.transaction_no + '&transaction_no=' + _this.state.payment_response.transaction_no + '&amount=' + _this.state.payment_response.amount + '&currency=' + _this.state.payment_response.currency + '&site_return_url=' + _this.state.payment_response.site_return_url + '&optional1=' + _this.state.payment_response.optional1 + '&optional2=' + _this.state.payment_response.optional2 + '&optional3=' + _this.state.payment_response.optional3);
                                }
                            })
                        });
                    }
                }
            });
    }
    componentDidMount() {
        var _this = this;
        console.log("tutelarEnablesssss",tutelarEnable);

        var getUrlParams = new URLSearchParams(window.location.search);
        var urlParams = getUrlParams.get('ref_id');
        var orderdata = getUrlParams.get('orderdata');
        var transaction_no = getUrlParams.get('transaction_no');
        var getOrderData = JSON.parse(orderdata);
        document.documentElement.style.setProperty('--main-theme-color','#'+getOrderData.merchant.theme_color);
        document.documentElement.style.setProperty('--main-primary-color','#'+getOrderData.merchant.primary_color);
        _this.setState({ref_id:urlParams,orderdata:orderdata,transaction_no:transaction_no, secret_key:getOrderData.merchant.secret_key,App_Details_enable:true}, () => {
            _this.setState({
                App_Details: {
                    "merchant" : {
                        "payment_gateway": getOrderData.merchant.payment_gateway,
                        "payment_screen_logo" : getOrderData.merchant.payment_screen_logo
                    },
                    "transaction_no" : getOrderData.transaction_no,
                    "sender_currency": getOrderData.sender_currency,
                    "payable_amount_in_sender_currency" : getOrderData.payable_amount_in_sender_currency
                },
                orderData: {
                    "customer" : getOrderData.customer,
                    "transaction" : {
                        "reference_token": getOrderData.transaction.reference_token,
                        "success_url" : GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?refid=' +  _this.state.transaction_no,
				        "cancel_url": GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel + '?refid=' + _this.state.transaction_no,
                    }
                }
            })
            var data = {
                "payment_reference" : _this.state.ref_id
            }
            dataservice.postHValues(GBLVAR.GET_SAMSUNG_TOKEN, {}, data)
			.then(function (response) {
                _this.setState({samsung_pay_token:response.data.payment_response}, () => {
                    window.seon.config({
                        session_id: `"${_this.state.transaction_no}"`,
                        audio_fingerprint: true,
                        canvas_fingerprint: true,
                        webgl_fingerprint: true,
                        onSuccess: function(message) {
                            console.log("success", message);
                            window.seon.getBase64Session(function(data) {
                                if (data) {
                                    console.log("Session payload", data);
                                    _this.setState({ payload : data }, () => {
                                        _this.completePayment();
                                    });
                                } else {
                                    _this.props.history.push(GBLVAR.PAGE_NAME.CardCancel);
                                    console.log("Failed to retrieve session data.");
                                }
                            });
                        },
                        onError: function(message) {
                            console.log("error", message);
                        }
                    });
                })
            });
        })
    }
    render() {
        return (
            <>
            {this.state.App_Details_enable &&
            <Header App_Details_from_SamsungPay={this.state.App_Details} />
            }
            <div className="fl_pay_section text-center">
                <div className="status_heading"> Payment Processing</div>
                <div className="status_trans_icon">   
                    <img src={imagePath('./loader.gif')} />
                </div>
                <div className="status_trans_message">Kindly DO NOT close, refresh or click back button.</div>
            </div>
            </>
        );
    }
}

const mapStateToProps = state => {
	return {
		App_Details : state.AppReducer.App_Details
	}
};
          
export default connect(mapStateToProps)(SamsungPay);





