import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {imagePath} from '../actions/ImagePath';
import GBLVAR from '../GlobalVariables/globalvaribles';

class SamsungCancel extends Component {
    constructor (props) {
      super(props);
      this.state = {
        successdata:{},
        cancelMessage:"",
        paydetails:{},
        App_Details_enable:false
      }
      this.goBack = this.goBack.bind(this);
    }
    goBack(){
        this.props.history.push(GBLVAR.PAGE_NAME.Home+'?refid='+this.state.transaction_no);
    }
    componentDidMount() {
        var _this = this;
        var getUrlParams = new URLSearchParams(window.location.search);
        var urlParams = getUrlParams.get('ref_id');
        var orderdata = getUrlParams.get('orderdata');
        var transaction_no = getUrlParams.get('transaction_no');
        // var getOrderData = JSON.parse(orderdata);
        var getOrderData = JSON.parse(decodeURI(orderdata));
        document.documentElement.style.setProperty('--main-theme-color','#'+getOrderData.merchant.theme_color);
        document.documentElement.style.setProperty('--main-primary-color','#'+getOrderData.merchant.primary_color);
        _this.setState({ref_id:urlParams,orderdata:orderdata,transaction_no:transaction_no, secret_key:getOrderData.merchant.secret_key,App_Details_enable:true}, () => {
            _this.setState({
                App_Details: {
                    "merchant" : {
                        "payment_gateway": getOrderData.merchant.payment_gateway,
                        "payment_screen_logo" : getOrderData.merchant.payment_screen_logo
                    },
                    "transaction_no" : getOrderData.transaction_no,
                    "sender_currency": getOrderData.sender_currency,
                    "payable_amount_in_sender_currency" : getOrderData.payable_amount_in_sender_currency
                },
                orderData: {
                    "customer" : getOrderData.customer,
                    "transaction" : {
                        "reference_token": getOrderData.transaction_no,
                        "success_url" : GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?refid=' +  _this.state.transaction_no,
				        "cancel_url": GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel + '?refid=' + _this.state.transaction_no,
                    }
                },
                transaction_no: getUrlParams.get('transaction_no'),
                optional1: getUrlParams.get('optional1'),
                optional2: getUrlParams.get('optional2'),
                optional3: getUrlParams.get('optional3'),
                site_return_url: getUrlParams.get('site_return_url'),
            }, () => {
                var returnUrl = _this.state.site_return_url;
                if(returnUrl !== ''){
                    var form = document.createElement("form");
                    form.setAttribute("method", "POST");
                    form.setAttribute("action", returnUrl);
                    form.setAttribute("enctype", "application/json");
                    var statusInput = document.createElement("input"); 
                    statusInput.setAttribute("type", "hidden"); 
                    statusInput.setAttribute("name", "status"); 
                    statusInput.setAttribute("id", "status"); 
                    statusInput.setAttribute("value", "error");
                    var transactionNoInput = document.createElement("input"); 
                    transactionNoInput.setAttribute("type", "hidden"); 
                    transactionNoInput.setAttribute("name", "transaction_no"); 
                    transactionNoInput.setAttribute("id", "transaction_no"); 
                    transactionNoInput.setAttribute("value", _this.state.transaction_no);
                    var foloosiInput = document.createElement("input"); 
                    foloosiInput.setAttribute("type", "hidden"); 
                    foloosiInput.setAttribute("name", "foloosi_payment_id"); 
                    foloosiInput.setAttribute("id", "foloosi_payment_id"); 
                    foloosiInput.setAttribute("value", _this.state.transaction_no);      
                    var foloosiOption1Input = document.createElement("input"); 
                    foloosiOption1Input.setAttribute("type", "hidden"); 
                    foloosiOption1Input.setAttribute("name", "optional1"); 
                    foloosiOption1Input.setAttribute("id", "optional1"); 
                    foloosiOption1Input.setAttribute("value", _this.state.optional1);
                    var foloosiOption2Input = document.createElement("input"); 
                    foloosiOption2Input.setAttribute("type", "hidden"); 
                    foloosiOption2Input.setAttribute("name", "optional2"); 
                    foloosiOption2Input.setAttribute("id", "optional2"); 
                    foloosiOption2Input.setAttribute("value", _this.state.optional2);
                    var foloosiOption3Input = document.createElement("input"); 
                    foloosiOption3Input.setAttribute("type", "hidden"); 
                    foloosiOption3Input.setAttribute("name", "optional3"); 
                    foloosiOption3Input.setAttribute("id", "optional3"); 
                    foloosiOption3Input.setAttribute("value", _this.state.optional3);     
                    form.appendChild(statusInput); 
                    form.appendChild(transactionNoInput); 
                    form.appendChild(foloosiInput); 
                    form.appendChild(foloosiOption1Input); 
                    form.appendChild(foloosiOption2Input); 
                    form.appendChild(foloosiOption3Input); 
                    document.body.appendChild(form);
                    form.submit();
                }else{
                    setTimeout(() => {
                        var data = {
                            "payment_status": "Transaction Cancelled",
                            "transaction_no": _this.state.transaction_no,
                            "amount": _this.state.App_Details.payable_amount_in_sender_currency,
                            "currency": _this.state.App_Details.sender_currency,
                            "site_return_url": _this.state.site_return_url,
                            "optional1": _this.state.optional1,
                            "optional2": _this.state.optional2,
                            "optional3": _this.state.optional3
                        }
                        var dataSuccess = {"status":"error","data": data}
                        window.top.parent.postMessage(dataSuccess, "*")
                        if(window.ReactNativeWebView !== undefined){
                            try{
                                window.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess));
                                window.top.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess));
                            }
                            catch(error){
                                window.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess), '*');
                                window.top.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess), '*');
                            }
                        }
                    }, 2000);
                }
            });
        })
    }
    render() {
        return (
            <>
            {this.state.App_Details_enable &&
            <Header App_Details_from_SamsungPay={this.state.App_Details} />
            }
            <div className="fl_pay_section text-center">
                <div className="status_heading"> Payment Cancelled</div>
                <div className="status_trans_icon">   
                    <img src={imagePath('./cancel.svg')} />
                </div>
                <div className="status_trans_message">Note : Samsung Pay not supported</div>
                <div className="fpl_mob_wrap"><a onClick={e => this.goBack()} className="status_error_btn">Retry Payment</a></div>
            </div>
            </>
        );
    }
}
          
const mapStateToProps = state => {
	return {
		App_Details : state.AppReducer.App_Details
	}
};
          
export default connect(mapStateToProps)(SamsungCancel);





