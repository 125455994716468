import {
  APP_LOAD,
  GET_ACCESS,
  UPDATE_PROPS,
  COUNTRY_DETAIL,
  CHANGE_VALUE,
  GET_QUERYDATA
} from '../constants/actionTypes';
const defaultState = {
  loading : true,
  countryFlag:false,
  App_Details : {},
  queryDataObject:{}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PROPS:      
      return {        
        ...state,
        App_Details : action.payload.App_Details
      };
    case APP_LOAD:
      return {        
        ...state,
        loading: false
      };
    case GET_ACCESS:
      return {        
        ...state,
        App_Details : action.payload
      };
    case COUNTRY_DETAIL:
      return {        
        ...state,...action.payload
      };
    case CHANGE_VALUE:
      return {        
        ...state,
        [action.payload.name] : action.payload.value
      }; 
    case GET_QUERYDATA:
        return {
          ...state,
          queryDataObject : action.payload
        }
    default : return state;
  }
}