import {
  SHOW_COUNTRY,
  SELECT_COUNTRY,
  GUEST_ONCHANGE,
  GET_ACCESS,
  GET_COUNTRY,
  FALSE_SCREEN,
  SET_NUMBER,
  GUEST_SIGNUP,
  INIT_TRANS,
  PAYMENT_SCREEN,
  UPDATE_PROPS,
  SET_COUNTRY,
  TRIM_NAME,
  CARD_CHANGE,
  ON_CHANGE,
  CHECK_CHANGE,
  GET_COUNTRY_DETAILS,
  TRUE_SCREEN
} from '../constants/actionTypes';
  
  const defaultState = {
    loading : true,
    paymentframe : false,
    countryFlag : false,
    selected_country : {},
    Guest : {
      email:"",
      name:"",
      ph_number:""
    },
    current_country : "",
    successdetails : {},
    initiate_transaction : {},
    card_data : {},
    card_save : false,
    click2pay_agree:true,
    click2pay_agree_card:false,
    pay_via_saved : "",
    getCountryDetails:{}
  };
  
  export default (state = defaultState, action) => {
    switch (action.type) { 
      case UPDATE_PROPS:
      return {
        ...state,
        successdetails : action.payload.successdetails,
        initiate_transaction : action.payload.initiate_transaction
      };
      case PAYMENT_SCREEN:
        return {
          ...state,
          paymentframe : true
        }
      case INIT_TRANS:
        return {   
          ...state,
          initiate_transaction : action.payload.data
        }
      case GUEST_SIGNUP:
        return {   
          ...state,
          successdetails : action.payload.data
        }
      case SET_NUMBER:
        return {   
          ...state,     
          Guest : {
            ...state.Guest,
            ph_number : action.payload
          }
      };
      case FALSE_SCREEN:
        return {        
          ...state,
          loading : false,
          paymentframe : false          
      };
      case TRUE_SCREEN:
        return {        
          ...state,
          loading : false,
          paymentframe : false          
      };
      case GET_COUNTRY:
        return {        
          ...state,
          current_country : action.payload.countryCode
      };
      case GET_ACCESS:
        return {        
          ...state,
          Guest : {
            ...state.Guest,
            email : action.payload.customer_email,
            name : action.payload.customer_name,
            ph_number : action.payload.customer_mobile
          }
      };   
      case TRIM_NAME:
        return {        
          ...state,
          Guest : {
            ...state.Guest,
            name : action.payload
          }
      };  
      case SHOW_COUNTRY:
      return {        
        ...state,
        countryFlag : !state.countryFlag
      };
      case SELECT_COUNTRY:
        return {        
          ...state,
          selected_country : {
            ...state.selected_country,
            country_code : action.payload.code,
            country_name : action.payload.name,
            country_flag : action.payload.flag,
            countryRegion : action.payload["2digit"],
          }          
      };   
      case SET_COUNTRY:
        return {        
          ...state,
          selected_country : {
            ...state.selected_country,
            ...action.payload
          }          
      }; 
      case GUEST_ONCHANGE:
        return {        
          ...state,
          Guest : {
            ...state.Guest,
            [action.payload.name] : action.payload.value
          }
      };  
      case CARD_CHANGE:
        return {  
          ...state,
          card_data : action.payload
        } 
      case ON_CHANGE:
        console.log("action",action)
        return {  
          ...state,

        }  
      case GET_COUNTRY_DETAILS:
      return {        
        ...state,
        get_country_details : action.payload
      }
      
    case CHECK_CHANGE:
      return {        
        ...state,
        ...action.payload
      } 
      default : return state;
    }
    
  }   
  