export const Countries = [
    {
      "2digit": "AF",
      "3digit": "AFG",
      "code": "+93",
      "flag": "af",
      "name": "Afghanistan"
    },
    {
      "2digit": "AL",
      "3digit": "ALB",
      "code": "+355",
      "flag": "al",
      "name": "Albania"
    },
    {
      "2digit": "DZ",
      "3digit": "DZA",
      "code": "+213",
      "flag": "dz",
      "name": "Algeria"
    },
    {
      "2digit": "AS",
      "3digit": "ASM",
      "code": "+1-684",
      "flag": "as",
      "name": "American Samoa"
    },
    {
      "2digit": "AD",
      "3digit": "AND",
      "code": "+376",
      "flag": "ad",
      "name": "Andorra"
    },
    {
      "2digit": "AO",
      "3digit": "AGO",
      "code": "+244",
      "flag": "ao",
      "name": "Angola"
    },
    {
      "2digit": "AI",
      "3digit": "AIA",
      "code": "+1-264",
      "flag": "ai",
      "name": "Anguilla"
    },
    {
      "2digit": "AQ",
      "3digit": "ATA",
      "code": "+672",
      "flag": "aq",
      "name": "Antarctica"
    },
    {
      "2digit": "AG",
      "3digit": "ATG",
      "code": "+1-268",
      "flag": "ag",
      "name": "Antigua and Barbuda"
    },
    {
      "2digit": "AR",
      "3digit": "ARG",
      "code": "+54",
      "flag": "ar",
      "name": "Argentina"
    },
    {
      "2digit": "AM",
      "3digit": "ARM",
      "code": "+374",
      "flag": "am",
      "name": "Armenia"
    },
    {
      "2digit": "AW",
      "3digit": "ABW",
      "code": "+297",
      "flag": "aw",
      "name": "Aruba"
    },
    {
      "2digit": "AU",
      "3digit": "AUS",
      "code": "+61",
      "flag": "au",
      "name": "Australia"
    },
    {
      "2digit": "AT",
      "3digit": "AUT",
      "code": "+43",
      "flag": "at",
      "name": "Austria"
    },
    {
      "2digit": "AZ",
      "3digit": "AZE",
      "code": "+994",
      "flag": "az",
      "name": "Azerbaijan"
    },
    {
      "2digit": "BS",
      "3digit": "BHS",
      "code": "+1-242",
      "flag": "bs",
      "name": "Bahamas"
    },
    {
      "2digit": "BH",
      "3digit": "BHR",
      "code": "+973",
      "flag": "bh",
      "name": "Bahrain"
    },
    {
      "2digit": "BD",
      "3digit": "BGD",
      "code": "+880",
      "flag": "bd",
      "name": "Bangladesh"
    },
    {
      "2digit": "BB",
      "3digit": "BRB",
      "code": "+1-246",
      "flag": "bb",
      "name": "Barbados"
    },
    {
      "2digit": "BY",
      "3digit": "BLR",
      "code": "+375",
      "flag": "by",
      "name": "Belarus"
    },
    {
      "2digit": "BE",
      "3digit": "BEL",
      "code": "+32",
      "flag": "be",
      "name": "Belgium"
    },
    {
      "2digit": "BZ",
      "3digit": "BLZ",
      "code": "+501",
      "flag": "bz",
      "name": "Belize"
    },
    {
      "2digit": "BJ",
      "3digit": "BEN",
      "code": "+229",
      "flag": "bj",
      "name": "Benin"
    },
    {
      "2digit": "BM",
      "3digit": "BMU",
      "code": "+1-441",
      "flag": "bm",
      "name": "Bermuda"
    },
    {
      "2digit": "BT",
      "3digit": "BTN",
      "code": "+975",
      "flag": "bt",
      "name": "Bhutan"
    },
    {
      "2digit": "BO",
      "3digit": "BOL",
      "code": "+591",
      "flag": "bo",
      "name": "Bolivia"
    },
    {
      "2digit": "BA",
      "3digit": "BIH",
      "code": "+387",
      "flag": "ba",
      "name": "Bosnia and Herzegovina"
    },
    {
      "2digit": "BW",
      "3digit": "BWA",
      "code": "+267",
      "flag": "bw",
      "name": "Botswana"
    },
    {
      "2digit": "BR",
      "3digit": "BRA",
      "code": "+55",
      "flag": "br",
      "name": "Brazil"
    },
    {
      "2digit": "IO",
      "3digit": "IOT",
      "code": "+246",
      "flag": "io",
      "name": "British Indian Ocean Territory"
    },
    {
      "2digit": "BN",
      "3digit": "BRN",
      "code": "+673",
      "flag": "bn",
      "name": "Brunei"
    },
    {
      "2digit": "BG",
      "3digit": "BGR",
      "code": "+359",
      "flag": "bg",
      "name": "Bulgaria"
    },
    {
      "2digit": "BF",
      "3digit": "BFA",
      "code": "+226",
      "flag": "bf",
      "name": "Burkina Faso"
    },
    {
      "2digit": "BI",
      "3digit": "BDI",
      "code": "+257",
      "flag": "bi",
      "name": "Burundi"
    },
    {
      "2digit": "KH",
      "3digit": "KHM",
      "code": "+855",
      "flag": "kh",
      "name": "Cambodia"
    },
    {
      "2digit": "CM",
      "3digit": "CMR",
      "code": "+237",
      "flag": "cm",
      "name": "Cameroon"
    },
    {
      "2digit": "CV",
      "3digit": "CPV",
      "code": "+238",
      "flag": "cv",
      "name": "Cape Verde"
    },
    {
      "2digit": "KY",
      "3digit": "CYM",
      "code": "+1-345",
      "flag": "ky",
      "name": "Cayman Islands"
    },
    {
      "2digit": "CF",
      "3digit": "CAF",
      "code": "+236",
      "flag": "cf",
      "name": "Central African Republic"
    },
    {
      "2digit": "TD",
      "3digit": "TCD",
      "code": "+235",
      "flag": "td",
      "name": "Chad"
    },
    {
      "2digit": "CL",
      "3digit": "CHL",
      "code": "+56",
      "flag": "cl",
      "name": "Chile"
    },
    {
      "2digit": "CN",
      "3digit": "CHN",
      "code": "+86",
      "flag": "cn",
      "name": "China"
    },
    {
      "2digit": "CX",
      "3digit": "CXR",
      "code": "+61",
      "flag": "cx",
      "name": "Christmas Island"
    },
    {
      "2digit": "CC",
      "3digit": "CCK",
      "code": "+61",
      "flag": "cc",
      "name": "Cocos Islands"
    },
    {
      "2digit": "CO",
      "3digit": "COL",
      "code": "+57",
      "flag": "co",
      "name": "Colombia"
    },
    {
      "2digit": "KM",
      "3digit": "COM",
      "code": "+269",
      "flag": "km",
      "name": "Comoros"
    },
    {
      "2digit": "CK",
      "3digit": "COK",
      "code": "+682",
      "flag": "ck",
      "name": "Cook Islands"
    },
    {
      "2digit": "CR",
      "3digit": "CRI",
      "code": "+506",
      "flag": "cr",
      "name": "Costa Rica"
    },
    {
      "2digit": "HR",
      "3digit": "HRV",
      "code": "+385",
      "flag": "hr",
      "name": "Croatia"
    },
    {
      "2digit": "CU",
      "3digit": "CUB",
      "code": "+53",
      "flag": "cu",
      "name": "Cuba"
    },
    {
      "2digit": "CW",
      "3digit": "CUW",
      "code": "+599",
      "flag": "cw",
      "name": "Curaçao"
    },
    {
      "2digit": "CY",
      "3digit": "CYP",
      "code": "+357",
      "flag": "cy",
      "name": "Cyprus"
    },
    {
      "2digit": "CZ",
      "3digit": "CZE",
      "code": "+420",
      "flag": "cz",
      "name": "Czechia"
    },
    {
      "2digit": "DK",
      "3digit": "DNK",
      "code": "+45",
      "flag": "dk",
      "name": "Denmark"
    },
    {
      "2digit": "DJ",
      "3digit": "DJI",
      "code": "+253",
      "flag": "dj",
      "name": "Djibouti"
    },
    {
      "2digit": "DM",
      "3digit": "DMA",
      "code": "+1-767",
      "flag": "dm",
      "name": "Dominica"
    },
    {
      "2digit": "DO",
      "3digit": "DOM",
      "code": "+1-809, 1-829, 1-849",
      "flag": "do",
      "name": "Dominican Republic"
    },
    {
      "2digit": "EC",
      "3digit": "ECU",
      "code": "+593",
      "flag": "ec",
      "name": "Ecuador"
    },
    {
      "2digit": "EG",
      "3digit": "EGY",
      "code": "+20",
      "flag": "eg",
      "name": "Egypt"
    },
    {
      "2digit": "SV",
      "3digit": "SLV",
      "code": "+503",
      "flag": "sv",
      "name": "El Salvador"
    },
    {
      "2digit": "GQ",
      "3digit": "GNQ",
      "code": "+240",
      "flag": "gq",
      "name": "Equatorial Guinea"
    },
    {
      "2digit": "ER",
      "3digit": "ERI",
      "code": "+291",
      "flag": "er",
      "name": "Eritrea"
    },
    {
      "2digit": "EE",
      "3digit": "EST",
      "code": "+372",
      "flag": "ee",
      "name": "Estonia"
    },
    {
      "2digit": "ET",
      "3digit": "ETH",
      "code": "+251",
      "flag": "et",
      "name": "Ethiopia"
    },
    {
      "2digit": "FK",
      "3digit": "FLK",
      "code": "+500",
      "flag": "fk",
      "name": "Falkland Islands"
    },
    {
      "2digit": "FO",
      "3digit": "FRO",
      "code": "+298",
      "flag": "fo",
      "name": "Faroe Islands"
    },
    {
      "2digit": "FJ",
      "3digit": "FJI",
      "code": "+679",
      "flag": "fj",
      "name": "Fiji"
    },
    {
      "2digit": "FI",
      "3digit": "FIN",
      "code": "+358",
      "flag": "fi",
      "name": "Finland"
    },
    {
      "2digit": "FR",
      "3digit": "FRA",
      "code": "+33",
      "flag": "fr",
      "name": "France"
    },
    {
      "2digit": "PF",
      "3digit": "PYF",
      "code": "+689",
      "flag": "pf",
      "name": "French Polynesia"
    },
    {
      "2digit": "GA",
      "3digit": "GAB",
      "code": "+241",
      "flag": "ga",
      "name": "Gabon"
    },
    {
      "2digit": "GM",
      "3digit": "GMB",
      "code": "+220",
      "flag": "gm",
      "name": "Gambia"
    },
    {
      "2digit": "GE",
      "3digit": "GEO",
      "code": "+995",
      "flag": "ge",
      "name": "Georgia"
    },
    {
      "2digit": "DE",
      "3digit": "DEU",
      "code": "+49",
      "flag": "de",
      "name": "Germany"
    },
    {
      "2digit": "GH",
      "3digit": "GHA",
      "code": "+233",
      "flag": "gh",
      "name": "Ghana"
    },
    {
      "2digit": "GI",
      "3digit": "GIB",
      "code": "+350",
      "flag": "gi",
      "name": "Gibraltar"
    },
    {
      "2digit": "GR",
      "3digit": "GRC",
      "code": "+30",
      "flag": "gr",
      "name": "Greece"
    },
    {
      "2digit": "GL",
      "3digit": "GRL",
      "code": "+299",
      "flag": "gl",
      "name": "Greenland"
    },
    {
      "2digit": "GD",
      "3digit": "GRD",
      "code": "+1-473",
      "flag": "gd",
      "name": "Grenada"
    },
    {
      "2digit": "GU",
      "3digit": "GUM",
      "code": "+1-671",
      "flag": "gu",
      "name": "Guam"
    },
    {
      "2digit": "GT",
      "3digit": "GTM",
      "code": "+502",
      "flag": "gt",
      "name": "Guatemala"
    },
    {
      "2digit": "GG",
      "3digit": "GGY",
      "code": "+44-1481",
      "flag": "gg",
      "name": "Guernsey"
    },
    {
      "2digit": "GN",
      "3digit": "GIN",
      "code": "+224",
      "flag": "gn",
      "name": "Guinea"
    },
    {
      "2digit": "GW",
      "3digit": "GNB",
      "code": "+245",
      "flag": "gw",
      "name": "Guinea-Bissau"
    },
    {
      "2digit": "GY",
      "3digit": "GUY",
      "code": "+592",
      "flag": "gy",
      "name": "Guyana"
    },
    {
      "2digit": "HT",
      "3digit": "HTI",
      "code": "+509",
      "flag": "ht",
      "name": "Haiti"
    },
    {
      "2digit": "HN",
      "3digit": "HND",
      "code": "+504",
      "flag": "hn",
      "name": "Honduras"
    },
    {
      "2digit": "HK",
      "3digit": "HKG",
      "code": "+852",
      "flag": "hk",
      "name": "Hong Kong"
    },
    {
      "2digit": "HU",
      "3digit": "HUN",
      "code": "+36",
      "flag": "hu",
      "name": "Hungary"
    },
    {
      "2digit": "IS",
      "3digit": "ISL",
      "code": "+354",
      "flag": "is",
      "name": "Iceland"
    },
    {
      "2digit": "IN",
      "3digit": "IND",
      "code": "+91",
      "flag": "in",
      "name": "India"
    },
    {
      "2digit": "ID",
      "3digit": "IDN",
      "code": "+62",
      "flag": "id",
      "name": "Indonesia"
    },
    {
      "2digit": "IR",
      "3digit": "IRN",
      "code": "+98",
      "flag": "ir",
      "name": "Iran"
    },
    {
      "2digit": "IQ",
      "3digit": "IRQ",
      "code": "+964",
      "flag": "iq",
      "name": "Iraq"
    },
    {
      "2digit": "IE",
      "3digit": "IRL",
      "code": "+353",
      "flag": "ie",
      "name": "Ireland"
    },
    {
      "2digit": "IM",
      "3digit": "IMN",
      "code": "+44-1624",
      "flag": "im",
      "name": "Isle of Man"
    },
    {
      "2digit": "IL",
      "3digit": "ISR",
      "code": "+972",
      "flag": "il",
      "name": "Israel"
    },
    {
      "2digit": "IT",
      "3digit": "ITA",
      "code": "+39",
      "flag": "it",
      "name": "Italy"
    },
    {
      "2digit": "CI",
      "3digit": "CIV",
      "code": "+225",
      "flag": "civ",
      "name": "Ivory Coast"
    },
    {
      "2digit": "JM",
      "3digit": "JAM",
      "code": "+1-876",
      "flag": "jm",
      "name": "Jamaica"
    },
    {
      "2digit": "JP",
      "3digit": "JPN",
      "code": "+81",
      "flag": "jp",
      "name": "Japan"
    },
    {
      "2digit": "JE",
      "3digit": "JEY",
      "code": "+44-1534",
      "flag": "je",
      "name": "Jersey"
    },
    {
      "2digit": "JO",
      "3digit": "JOR",
      "code": "+962",
      "flag": "jo",
      "name": "Jordan"
    },
    {
      "2digit": "KZ",
      "3digit": "KAZ",
      "code": "+7",
      "flag": "kz",
      "name": "Kazakhstan"
    },
    {
      "2digit": "KE",
      "3digit": "KEN",
      "code": "+254",
      "flag": "ke",
      "name": "Kenya"
    },
    {
      "2digit": "KI",
      "3digit": "KIR",
      "code": "+686",
      "flag": "ki",
      "name": "Kiribati"
    },
    {
      "2digit": "KW",
      "3digit": "KWT",
      "code": "+965",
      "flag": "kw",
      "name": "Kuwait"
    },
    {
      "2digit": "KG",
      "3digit": "KGZ",
      "code": "+996",
      "flag": "kg",
      "name": "Kyrgyzstan"
    },
    {
      "2digit": "LA",
      "3digit": "LAO",
      "code": "+856",
      "flag": "la",
      "name": "Laos"
    },
    {
      "2digit": "LV",
      "3digit": "LVA",
      "code": "+371",
      "flag": "lv",
      "name": "Latvia"
    },
    {
      "2digit": "LB",
      "3digit": "LBN",
      "code": "+961",
      "flag": "lb",
      "name": "Lebanon"
    },
    {
      "2digit": "LS",
      "3digit": "LSO",
      "code": "+266",
      "flag": "ls",
      "name": "Lesotho"
    },
    {
      "2digit": "LR",
      "3digit": "LBR",
      "code": "+231",
      "flag": "lr",
      "name": "Liberia"
    },
    {
      "2digit": "LY",
      "3digit": "LBY",
      "code": "+218",
      "flag": "ly",
      "name": "Libya"
    },
    {
      "2digit": "LI",
      "3digit": "LIE",
      "code": "+423",
      "flag": "li",
      "name": "Liechtenstein"
    },
    {
      "2digit": "LT",
      "3digit": "LTU",
      "code": "+370",
      "flag": "lt",
      "name": "Lithuania"
    },
    {
      "2digit": "LU",
      "3digit": "LUX",
      "code": "+352",
      "flag": "lu",
      "name": "Luxembourg"
    },
    {
      "2digit": "MO",
      "3digit": "MAC",
      "code": "+853",
      "flag": "mo",
      "name": "Macao"
    },
    {
      "2digit": "MK",
      "3digit": "MKD",
      "code": "+389",
      "flag": "mk",
      "name": "Macedonia"
    },
    {
      "2digit": "MG",
      "3digit": "MDG",
      "code": "+261",
      "flag": "mg",
      "name": "Madagascar"
    },
    {
      "2digit": "MW",
      "3digit": "MWI",
      "code": "+265",
      "flag": "mw",
      "name": "Malawi"
    },
    {
      "2digit": "MY",
      "3digit": "MYS",
      "code": "+60",
      "flag": "my",
      "name": "Malaysia"
    },
    {
      "2digit": "MV",
      "3digit": "MDV",
      "code": "+960",
      "flag": "mv",
      "name": "Maldives"
    },
    {
      "2digit": "ML",
      "3digit": "MLI",
      "code": "+223",
      "flag": "ml",
      "name": "Mali"
    },
    {
      "2digit": "MT",
      "3digit": "MLT",
      "code": "+356",
      "flag": "mt",
      "name": "Malta"
    },
    {
      "2digit": "MH",
      "3digit": "MHL",
      "code": "+692",
      "flag": "mh",
      "name": "Marshall Islands"
    },
    {
      "2digit": "MR",
      "3digit": "MRT",
      "code": "+222",
      "flag": "mr",
      "name": "Mauritania"
    },
    {
      "2digit": "MU",
      "3digit": "MUS",
      "code": "+230",
      "flag": "mu",
      "name": "Mauritius"
    },
    {
      "2digit": "YT",
      "3digit": "MYT",
      "code": "+262",
      "flag": "yt",
      "name": "Mayotte"
    },
    {
      "2digit": "MX",
      "3digit": "MEX",
      "code": "+52",
      "flag": "mx",
      "name": "Mexico"
    },
    {
      "2digit": "FM",
      "3digit": "FSM",
      "code": "+691",
      "flag": "fm",
      "name": "Micronesia"
    },
    {
      "2digit": "MD",
      "3digit": "MDA",
      "code": "+373",
      "flag": "md",
      "name": "Moldova"
    },
    {
      "2digit": "MC",
      "3digit": "MCO",
      "code": "+377",
      "flag": "mc",
      "name": "Monaco"
    },
    {
      "2digit": "MN",
      "3digit": "MNG",
      "code": "+976",
      "flag": "mn",
      "name": "Mongolia"
    },
    {
      "2digit": "ME",
      "3digit": "MNE",
      "code": "+382",
      "flag": "me",
      "name": "Montenegro"
    },
    {
      "2digit": "MS",
      "3digit": "MSR",
      "code": "+1-664",
      "flag": "ms",
      "name": "Montserrat"
    },
    {
      "2digit": "MA",
      "3digit": "MAR",
      "code": "+212",
      "flag": "ma",
      "name": "Morocco"
    },
    {
      "2digit": "MZ",
      "3digit": "MOZ",
      "code": "+258",
      "flag": "mz",
      "name": "Mozambique"
    },
    {
      "2digit": "MM",
      "3digit": "MMR",
      "code": "+95",
      "flag": "mm",
      "name": "Myanmar"
    },
    {
      "2digit": "NA",
      "3digit": "NAM",
      "code": "+264",
      "flag": "na",
      "name": "Namibia"
    },
    {
      "2digit": "NR",
      "3digit": "NRU",
      "code": "+674",
      "flag": "nr",
      "name": "Nauru"
    },
    {
      "2digit": "NP",
      "3digit": "NPL",
      "code": "+977",
      "flag": "np",
      "name": "Nepal"
    },
    {
      "2digit": "NL",
      "3digit": "NLD",
      "code": "+31",
      "flag": "nl",
      "name": "Netherlands"
    },
    {
      "2digit": "NC",
      "3digit": "NCL",
      "code": "+687",
      "flag": "nc",
      "name": "New Caledonia"
    },
    {
      "2digit": "NZ",
      "3digit": "NZL",
      "code": "+64",
      "flag": "nz",
      "name": "New Zealand"
    },
    {
      "2digit": "NI",
      "3digit": "NIC",
      "code": "+505",
      "flag": "ni",
      "name": "Nicaragua"
    },
    {
      "2digit": "NE",
      "3digit": "NER",
      "code": "+227",
      "flag": "ne",
      "name": "Niger"
    },
    {
      "2digit": "NG",
      "3digit": "NGA",
      "code": "+234",
      "flag": "ng",
      "name": "Nigeria"
    },
    {
      "2digit": "NU",
      "3digit": "NIU",
      "code": "+683",
      "flag": "nu",
      "name": "Niue"
    },
    {
      "2digit": "MP",
      "3digit": "MNP",
      "code": "+1-670",
      "flag": "mp",
      "name": "Northern Mariana Islands"
    },
    {
      "2digit": "NO",
      "3digit": "NOR",
      "code": "+47",
      "flag": "no",
      "name": "Norway"
    },
    {
      "2digit": "OM",
      "3digit": "OMN",
      "code": "+968",
      "flag": "om",
      "name": "Oman"
    },
    {
      "2digit": "PK",
      "3digit": "PAK",
      "code": "+92",
      "flag": "pk",
      "name": "Pakistan"
    },
    {
      "2digit": "PW",
      "3digit": "PLW",
      "code": "+680",
      "flag": "pw",
      "name": "Palau"
    },
    {
      "2digit": "PS",
      "3digit": "PSE",
      "code": "+970",
      "flag": "ps",
      "name": "Palestine"
    },
    {
      "2digit": "PA",
      "3digit": "PAN",
      "code": "+507",
      "flag": "pa",
      "name": "Panama"
    },
    {
      "2digit": "PG",
      "3digit": "PNG",
      "code": "+675",
      "flag": "pg",
      "name": "Papua New Guinea"
    },
    {
      "2digit": "PY",
      "3digit": "PRY",
      "code": "+595",
      "flag": "py",
      "name": "Paraguay"
    },
    {
      "2digit": "PE",
      "3digit": "PER",
      "code": "+51",
      "flag": "pe",
      "name": "Peru"
    },
    {
      "2digit": "PH",
      "3digit": "PHL",
      "code": "+63",
      "flag": "ph",
      "name": "Philippines"
    },
    {
      "2digit": "PN",
      "3digit": "PCN",
      "code": "+64",
      "flag": "pn",
      "name": "Pitcairn"
    },
    {
      "2digit": "PL",
      "3digit": "POL",
      "code": "+48",
      "flag": "pl",
      "name": "Poland"
    },
    {
      "2digit": "PT",
      "3digit": "PRT",
      "code": "+351",
      "flag": "pt",
      "name": "Portugal"
    },
    {
      "2digit": "PR",
      "3digit": "PRI",
      "code": "+1-787, 1-939",
      "flag": "pr",
      "name": "Puerto Rico"
    },
    {
      "2digit": "QA",
      "3digit": "QAT",
      "code": "+974",
      "flag": "qa",
      "name": "Qatar"
    },
    {
      "2digit": "CG",
      "3digit": "COG",
      "code": "+242",
      "flag": "cg",
      "name": "Republic of the Congo"
    },
    {
      "2digit": "RE",
      "3digit": "REU",
      "code": "+262",
      "flag": "re",
      "name": "Réunion"
    },
    {
      "2digit": "RO",
      "3digit": "ROU",
      "code": "+40",
      "flag": "ro",
      "name": "Romania"
    },
    {
      "2digit": "RU",
      "3digit": "RUS",
      "code": "+7",
      "flag": "ru",
      "name": "Russia"
    },
    {
      "2digit": "RW",
      "3digit": "RWA",
      "code": "+250",
      "flag": "rw",
      "name": "Rwanda"
    },
    {
      "2digit": "BL",
      "3digit": "BLM",
      "code": "+590",
      "flag": "bl",
      "name": "Saint Barthélemy"
    },
    {
      "2digit": "SH",
      "3digit": "SHN",
      "code": "+290",
      "flag": "sh",
      "name": "Saint Helena"
    },
    {
      "2digit": "KN",
      "3digit": "KNA",
      "code": "+1-869",
      "flag": "kn",
      "name": "Saint Kitts and Nevis"
    },
    {
      "2digit": "LC",
      "3digit": "LCA",
      "code": "+1-758",
      "flag": "lc",
      "name": "Saint Lucia"
    },
    {
      "2digit": "MF",
      "3digit": "MAF",
      "code": "+590",
      "flag": "mf",
      "name": "Saint Martin"
    },
    {
      "2digit": "PM",
      "3digit": "SPM",
      "code": "+508",
      "flag": "pm",
      "name": "Saint Pierre and Miquelon"
    },
    {
      "2digit": "VC",
      "3digit": "VCT",
      "code": "+1-784",
      "flag": "vc",
      "name": "Saint Vincent and the Grenadines"
    },
    {
      "2digit": "WS",
      "3digit": "WSM",
      "code": "+685",
      "flag": "ws",
      "name": "Samoa"
    },
    {
      "2digit": "SM",
      "3digit": "SMR",
      "code": "+378",
      "flag": "sm",
      "name": "San Marino"
    },
    {
      "2digit": "ST",
      "3digit": "STP",
      "code": "+239",
      "flag": "st",
      "name": "Sao Tome and Principe"
    },
    {
      "2digit": "SA",
      "3digit": "SAU",
      "code": "+966",
      "flag": "sa",
      "name": "Saudi Arabia"
    },
    {
      "2digit": "SN",
      "3digit": "SEN",
      "code": "+221",
      "flag": "sn",
      "name": "Senegal"
    },
    {
      "2digit": "RS",
      "3digit": "SRB",
      "code": "+381",
      "flag": "rs",
      "name": "Serbia"
    },
    {
      "2digit": "SC",
      "3digit": "SYC",
      "code": "+248",
      "flag": "sc",
      "name": "Seychelles"
    },
    {
      "2digit": "SL",
      "3digit": "SLE",
      "code": "+232",
      "flag": "sl",
      "name": "Sierra Leone"
    },
    {
      "2digit": "SG",
      "3digit": "SGP",
      "code": "+65",
      "flag": "sg",
      "name": "Singapore"
    },
    {
      "2digit": "SX",
      "3digit": "SXM",
      "code": "+1-721",
      "flag": "sx",
      "name": "Sint Maarten"
    },
    {
      "2digit": "SK",
      "3digit": "SVK",
      "code": "+421",
      "flag": "sk",
      "name": "Slovakia"
    },
    {
      "2digit": "SI",
      "3digit": "SVN",
      "code": "+386",
      "flag": "si",
      "name": "Slovenia"
    },
    {
      "2digit": "SB",
      "3digit": "SLB",
      "code": "+677",
      "flag": "sb",
      "name": "Solomon Islands"
    },
    {
      "2digit": "SO",
      "3digit": "SOM",
      "code": "+252",
      "flag": "so",
      "name": "Somalia"
    },
    {
      "2digit": "ZA",
      "3digit": "ZAF",
      "code": "+27",
      "flag": "za",
      "name": "South Africa"
    },
    {
      "2digit": "KR",
      "3digit": "KOR",
      "code": "+82",
      "flag": "kr",
      "name": "South Korea"
    },
    {
      "2digit": "SS",
      "3digit": "SSD",
      "code": "+211",
      "flag": "ss",
      "name": "South Sudan"
    },
    {
      "2digit": "ES",
      "3digit": "ESP",
      "code": "+34",
      "flag": "es",
      "name": "Spain"
    },
    {
      "2digit": "LK",
      "3digit": "LKA",
      "code": "+94",
      "flag": "lk",
      "name": "Sri Lanka"
    },
    {
      "2digit": "SD",
      "3digit": "SDN",
      "code": "+249",
      "flag": "sd",
      "name": "Sudan"
    },
    {
      "2digit": "SR",
      "3digit": "SUR",
      "code": "+597",
      "flag": "sr",
      "name": "Suriname"
    },
    {
      "2digit": "SJ",
      "3digit": "SJM",
      "code": "+47",
      "flag": "sj",
      "name": "Svalbard and Jan Mayen"
    },
    {
      "2digit": "SE",
      "3digit": "SWE",
      "code": "+46",
      "flag": "se",
      "name": "Sweden"
    },
    {
      "2digit": "CH",
      "3digit": "CHE",
      "code": "+41",
      "flag": "ch",
      "name": "Switzerland"
    },
    {
      "2digit": "SY",
      "3digit": "SYR",
      "code": "+963",
      "flag": "sy",
      "name": "Syria"
    },
    {
      "2digit": "TW",
      "3digit": "TWN",
      "code": "+886",
      "flag": "tw",
      "name": "Taiwan"
    },
    {
      "2digit": "TJ",
      "3digit": "TJK",
      "code": "+992",
      "flag": "tj",
      "name": "Tajikistan"
    },
    {
      "2digit": "TZ",
      "3digit": "TZA",
      "code": "+255",
      "flag": "tz",
      "name": "Tanzania"
    },
    {
      "2digit": "TH",
      "3digit": "THA",
      "code": "+66",
      "flag": "th",
      "name": "Thailand"
    },
    {
      "2digit": "TG",
      "3digit": "TGO",
      "code": "+228",
      "flag": "tg",
      "name": "Togo"
    },
    {
      "2digit": "TK",
      "3digit": "TKL",
      "code": "+690",
      "flag": "tk",
      "name": "Tokelau"
    },
    {
      "2digit": "TO",
      "3digit": "TON",
      "code": "+676",
      "flag": "to",
      "name": "Tonga"
    },
    {
      "2digit": "TT",
      "3digit": "TTO",
      "code": "+1-868",
      "flag": "tt",
      "name": "Trinidad and Tobago"
    },
    {
      "2digit": "TN",
      "3digit": "TUN",
      "code": "+216",
      "flag": "tn",
      "name": "Tunisia"
    },
    {
      "2digit": "TR",
      "3digit": "TUR",
      "code": "+90",
      "flag": "tr",
      "name": "Turkey"
    },
    {
      "2digit": "TM",
      "3digit": "TKM",
      "code": "+993",
      "flag": "tm",
      "name": "Turkmenistan"
    },
    {
      "2digit": "TC",
      "3digit": "TCA",
      "code": "+1-649",
      "flag": "tc",
      "name": "Turks and Caicos Islands"
    },
    {
      "2digit": "TV",
      "3digit": "TUV",
      "code": "+688",
      "flag": "tv",
      "name": "Tuvalu"
    },
    {
      "2digit": "UG",
      "3digit": "UGA",
      "code": "+256",
      "flag": "ug",
      "name": "Uganda"
    },
    {
      "2digit": "UA",
      "3digit": "UKR",
      "code": "+380",
      "flag": "ua",
      "name": "Ukraine"
    },
    {
      "2digit": "AE",
      "3digit": "ARE",
      "code": "+971",
      "flag": "ae",
      "name": "United Arab Emirates"
    },
    {
      "2digit": "GB",
      "3digit": "GBR",
      "code": "+44",
      "flag": "gb",
      "name": "United Kingdom"
    },
    {
      "2digit": "US",
      "3digit": "USA",
      "code": "+1",
      "flag": "us",
      "name": "United States of America"
    },
    {
      "2digit": "CA",
      "3digit": "CAN",
      "code": "+1",
      "flag": "ca",
      "name": "Canada"
    },
    {
      "2digit": "UY",
      "3digit": "URY",
      "code": "+598",
      "flag": "uy",
      "name": "Uruguay"
    },
    {
      "2digit": "UZ",
      "3digit": "UZB",
      "code": "+998",
      "flag": "uz",
      "name": "Uzbekistan"
    },
    {
      "2digit": "VU",
      "3digit": "VUT",
      "code": "+678",
      "flag": "vu",
      "name": "Vanuatu"
    },
    {
      "2digit": "VE",
      "3digit": "VEN",
      "code": "+58",
      "flag": "ve",
      "name": "Venezuela"
    },
    {
      "2digit": "VN",
      "3digit": "VNM",
      "code": "+84",
      "flag": "vn",
      "name": "Vietnam"
    },
    {
      "2digit": "VG",
      "3digit": "VGB",
      "code": "+1-284",
      "flag": "vg",
      "name": "Virgin Islands (British)"
    },
    {
      "2digit": "VI",
      "3digit": "VIR",
      "code": "+1-340",
      "flag": "vi",
      "name": "Virgin Islands (U.S.)"
    },
    {
      "2digit": "WF",
      "3digit": "WLF",
      "code": "+681",
      "flag": "wf",
      "name": "Wallis and Futuna"
    },
    {
      "2digit": "EH",
      "3digit": "ESH",
      "code": "+212",
      "flag": "eh",
      "name": "Western Sahara"
    },
    {
      "2digit": "YE",
      "3digit": "YEM",
      "code": "+967",
      "flag": "ye",
      "name": "Yemen"
    },
    {
      "2digit": "ZM",
      "3digit": "ZMB",
      "code": "+260",
      "flag": "zm",
      "name": "Zambia"
    },
    {
      "2digit": "ZW",
      "3digit": "ZWE",
      "code": "+263",
      "flag": "zw",
      "name": "Zimbabwe"
    }
]