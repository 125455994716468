import React, { Component } from 'react';
import {imagePath} from '../actions/ImagePath';

class Footer extends Component {
    render() {
        return (
            <div className="fpl_merchant_rightBot">
                <img className="pull-left" src={imagePath('./c2p.svg')} alt="" />
                <img className="pull-left" src={imagePath('./pipe.svg')} alt="" />
                <img className="pull-left" src={imagePath('./mastercard.svg')} alt="" />
                <img className="pull-left" src={imagePath('./visa.svg')} alt="" />
                <img className="pull-left" src={imagePath('./amex.svg')} alt="" />
                <img src={imagePath('./pci.png')} alt="" />                
            </div>
        );
    }
}
          
export default Footer;