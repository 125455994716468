import React, { Component } from 'react';
import { connect } from 'react-redux';

class Header extends Component {
    render() {
        var App_Details;
        if(this.props.App_Details && Object.keys(this.props.App_Details).length === 0){
            App_Details = this.props.App_Details_from_SamsungPay;
        } else{
            App_Details = this.props.App_Details;
        }
        return (
            <header>
                {App_Details &&
                <>
                {App_Details.merchant !== undefined && App_Details.custom_design_applicable == 1 &&
                    <div className="fl_head_left">
                        <img src={App_Details.merchant.payment_screen_logo} />
                    </div>
                }     
                <div className="fl_head_right">
                    {App_Details.hide_foloosi_reference == 0 && 
                    <div className="fl_id">#{App_Details.transaction_no !== undefined ? App_Details.transaction_no : <span className="check_empty_value">Transaction ID</span>}</div>
                    }
                    <div className="fl_pay_text">Amount Payable</div>
                    <div className="fl_min_pay_amt">{App_Details.sender_currency} {App_Details.payable_amount_in_sender_currency !== undefined ? App_Details.payable_amount_in_sender_currency : <span className="check_empty_value">Amount Payable</span>}</div>
                </div>
                </>
                }
          </header>
        );
    }
}

const mapStateToProps = state => {
    return {
        App_Details : state.AppReducer.App_Details
    }
};
  

export default connect(mapStateToProps)(Header);