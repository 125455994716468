import React, { Component } from 'react';
import Footer from './Footer';
import Loader from './Loader';
import Header from './Header';
import { connect } from 'react-redux';
import { GET_ACCESS, GET_QUERYDATA } from '../constants/actionTypes';
import GBLVAR from '../GlobalVariables/globalvaribles';
import dataservice from '../Services/DataService';

class Splashscreen extends Component {
	componentDidMount() {
		var url = window.location.search;
		var query = url.replace("?", '').replace(/%22/g, '"');
		var querydata = JSON.parse(query);
		querydata.access_for = 'developer_api';  
		this.props.onLoad(querydata)
		this.props.onDataLoad(querydata)
		localStorage.setItem('params', JSON.stringify(querydata))
	}
	componentWillReceiveProps(nextProps){
		if (this.props.App_Details !== nextProps.App_Details) {
			this.props.history.push(GBLVAR.PAGE_NAME.Home+'?refid='+nextProps.App_Details.transaction_no);
		}
    }
  	render() {  
		return (
			<div className="fl_pay_section text-center">  
				<Loader />
			</div>
		);
  	}
}
const mapStateToProps = state => {
    return {
        App_Details : state.AppReducer.App_Details,
		queryDataObject: state.AppReducer.queryDataObject
    }
};
  
const mapDispatchToProps = dispatch => ({
	onLoad: (header) => dispatch({ type: GET_ACCESS, payload: dataservice.getAccess(GBLVAR.API_LIST.API_ACCESS,header) }),
	onDataLoad: (querydata) => dispatch({ type: GET_QUERYDATA, payload:querydata  })

});

export default connect(mapStateToProps, mapDispatchToProps)(Splashscreen);