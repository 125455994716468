import React, { Component } from 'react';
import { connect } from 'react-redux';
import { imagePath } from '../actions/ImagePath';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import axios from 'axios';
import 'url-search-params-polyfill';
class Success extends Component {
    constructor(props) {
        super(props);
        var secret_key = window?.parent?.queryDataObject?.secret_key;
        var App_Details = window?.parent?.App_Details;
        var successdetails = window?.parent?.successdetails;
        var initiate_transaction = window?.parent?.initiate_transaction;
        var card_data = window?.parent?.card_data;
        var card_save = window?.parent?.card_save;
        var pay_via_saved = window?.parent?.pay_via_saved;
        this.state = {
            successdetails: successdetails,
            payment_transaction_id: "",
            reference_id: "",
            initiate_transaction: initiate_transaction,
            secret_key: secret_key,
            App_Details: App_Details,
            close_popup: false,
            successdata: {},
            card_data: card_data,
            card_save: card_save === true ? "Yes" : "No",
            pay_via_saved: pay_via_saved,
            success_screen : false
        }
        this.paymentComplete = this.paymentComplete.bind(this);
    }
    paymentComplete() {
        var _this=this;
        var returnUrl = decodeURIComponent(_this.state.site_return_url);
        if(returnUrl !== ''){
            var form = document.createElement("form");
            form.setAttribute("method", "POST");
            form.setAttribute("action", returnUrl);
            form.setAttribute("enctype", "application/json");
            form.setAttribute("target","_top");
            var statusInput = document.createElement("input"); 
            statusInput.setAttribute("type", "hidden"); 
            statusInput.setAttribute("name", "status"); 
            statusInput.setAttribute("id", "status"); 
            statusInput.setAttribute("value", "success");
            var transactionNoInput = document.createElement("input"); 
            transactionNoInput.setAttribute("type", "hidden"); 
            transactionNoInput.setAttribute("name", "transaction_no"); 
            transactionNoInput.setAttribute("id", "transaction_no"); 
            transactionNoInput.setAttribute("value", _this.state.transaction_no);
            var foloosiInput = document.createElement("input"); 
            foloosiInput.setAttribute("type", "hidden"); 
            foloosiInput.setAttribute("name", "foloosi_payment_id"); 
            foloosiInput.setAttribute("id", "foloosi_payment_id"); 
            foloosiInput.setAttribute("value", _this.state.transaction_no);      
            var foloosiOption1Input = document.createElement("input"); 
            foloosiOption1Input.setAttribute("type", "hidden"); 
            foloosiOption1Input.setAttribute("name", "optional1"); 
            foloosiOption1Input.setAttribute("id", "optional1"); 
            foloosiOption1Input.setAttribute("value", _this.state.optional1);
            var foloosiOption2Input = document.createElement("input"); 
            foloosiOption2Input.setAttribute("type", "hidden"); 
            foloosiOption2Input.setAttribute("name", "optional2"); 
            foloosiOption2Input.setAttribute("id", "optional2"); 
            foloosiOption2Input.setAttribute("value", _this.state.optional2);
            var foloosiOption3Input = document.createElement("input"); 
            foloosiOption3Input.setAttribute("type", "hidden"); 
            foloosiOption3Input.setAttribute("name", "optional3"); 
            foloosiOption3Input.setAttribute("id", "optional3"); 
            foloosiOption3Input.setAttribute("value", _this.state.optional3);        
            if(_this.state.subscription_no){
                var foloosiSubNoInput = document.createElement("input"); 
                foloosiSubNoInput.setAttribute("type", "hidden"); 
                foloosiSubNoInput.setAttribute("name", "subscription_no"); 
                foloosiSubNoInput.setAttribute("id", "subscription_no"); 
                foloosiSubNoInput.setAttribute("value", _this.state.subscription_no);
                form.appendChild(foloosiSubNoInput); 
            }      
            form.appendChild(statusInput); 
            form.appendChild(transactionNoInput); 
            form.appendChild(foloosiInput); 
            form.appendChild(foloosiOption1Input); 
            form.appendChild(foloosiOption2Input); 
            form.appendChild(foloosiOption3Input); 
            document.body.appendChild(form);
            form.submit();
        }else{
            setTimeout(() => {
                var data = {
                    "payment_status": "Transaction Successful",
                    "transaction_no": _this.state.transaction_no,
                    "amount": _this.state.amount,
                    "currency": _this.state.currency,
                    "site_return_url": decodeURIComponent(_this.state.site_return_url),
                    "optional1": _this.state.optional1,
                    "optional2": _this.state.optional2,
                    "optional3": _this.state.optional3
                }
                var dataSuccess = {"status":"success","data": data}
                window.top.parent.postMessage(dataSuccess, "*")
                if(window.ReactNativeWebView !== undefined){
                    try{
                        window.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess));
                        window.top.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess));
                    }
                    catch(error){
                        window.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess), '*');
                        window.top.ReactNativeWebView.postMessage(JSON.stringify(dataSuccess), '*');
                    }
                }
            }, 2000);
        }
    }
    componentDidMount() {
        var _this = this;
        var getUrlParams = new URLSearchParams(window.location.search);
        var urlParams = JSON.parse(getUrlParams.get('q'));
        _this.setState({
            reference_id:urlParams.reference_id,transaction_no:urlParams.transaction_no,currency:urlParams.currency,amount:urlParams.amount,site_return_url:urlParams.site_return_url,optional1:urlParams.optional1,optional2:urlParams.optional2,optional3:urlParams.optional3
        },()=>{
            _this.paymentComplete();
        })
    }
    render() {
        return (
            <div className="width_100 text-center">
                <div className="status_heading"> Payment Completed!</div>
                <div className="status_heading">Please wait to be redirected.</div>
                {window?.parent?.App_Details?.hide_foloosi_reference == 0 && 
                <div className="status_trans_id">Payment ID - #{this.state.transaction_no}</div>
                }
                <div className="status_trans_icon">   
                    <img src={imagePath('./success.svg')} />
                </div>
                <div className="status_trans_message">You have successfully paid {this.state.currency} {this.state.amount}</div>
                <div className="status_trans_message">Your order might be cancelled if you don't wait to be redirected back to the store.</div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        state: ""
    }
};

export default connect(mapStateToProps)(Success);
